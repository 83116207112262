import { observable } from "mobx";
import { deleteFaceFileDB } from "../../../api/MetaverseApi";
import MetaverseRepository from "../../../data/repository/MetaverseRepository";
import { MqttStore } from "../../domain/MqttStore";
import { LikuStore } from "../LikuStore";
import { getCookie } from "../../../utils/cookie";
import { PlayerUiStore } from "../player/PlayerUiStore";
import { KidUiStore } from "../KidUiStore";
import { PlayerStore } from "../../domain/PlayerStore";
import { PlayerBackgroundUiStore } from "../player/PlayerBackgroundUiStore";

// 처리과정이 단순할 때는 ui store의 여기 MetaverseUiStore에서 처리
const MetaverseContentsUiStore = observable({
    defaultFaceImageList: null,
    faceImageList: {},
    idListFromDB: [],
    allImage: null,
    deleteFacePatchListDB: [],
    FacePatchListFromDB: {},
    FacePatchListFromMemory: {},
    FacePatchPositon: [],
    contentUUID: "",
    // curState: true, //true = memory, false = last content
    state: true, //true = memory, false = last content
    isFirst: true,
    // isComplete: false,
    // top: [],
    // left: [],
    // zoomFactorList: [],
    countState: false,
    countVal: 0,
    numFrame: null,
    tempFrame: null,
    frameData: null,
    nextIndex: null,
    connectFaceImagePathList: [],
    // connectFaceImagePath: null,
    faceImageState: false,
    frameList: [],
    frameimageList: [],
    isReady: false,
    isNewPhoto: false,
    isDelete: false,
    birthdayPeople: [1, 2, 3, 4, 5],
    birthdayMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    selectedBirthdayPeople: null,
    selectedBirthdayMonth: null,

    paddingLeft:
        window.innerWidth <= window.innerHeight * 1.44
            ? 0
            : (window.innerWidth - window.innerHeight * 1.44) / 2,
    videoCurrentWidth: 0,

    async init() {
        // console.log("------metaverse init: ");
        if (this.isFirst) {
            this.setIsFirst(false);
            await this.getFaceImageList();
            // console.log("InitialFaceImageList", this.faceImageList);
            // this.idListFromDB = [];
            this.setIdListFromDB([]);
            // this.FacePatchListFromDB = {};
            this.setFacePatchListFromDB({});
            // await Object.keys(this.faceImageList).map((value, index)=>{
            //     this.idListFromDB.push(this.faceImageList[value].id);
            //     this.FacePatchListFromDB[value] = {id: this.faceImageList[value].id, path: this.faceImageList[value].path, imageWidth: this.faceImageList[value].imageWidth,
            //         imageHeight: this.faceImageList[value].imageHeight, category: 'idListFromDB'};
            // });
            for (const value of Object.keys(this.faceImageList)) {
                this.idListFromDB.push(this.faceImageList[value].id);
                this.FacePatchListFromDB[value] = {
                    id: this.faceImageList[value].id,
                    path: this.faceImageList[value].path,
                    imageWidth: this.faceImageList[value].imageWidth,
                    imageHeight: this.faceImageList[value].imageHeight,
                    category: "idListFromDB",
                };
            }
        }
    },

    // async update(data) {
    //     if(Object.keys(MetaverseContentsStore.faceImageList).length >= 0){
    //         MetaverseContentsStore.faceImageList = data;
    //     }
    //     console.log("MetaverseContentsStoreFaceImageList", MetaverseContentsStore.faceImageList);
    // },

    initFaceImageList(list) {
        let copyFaceImageList = {};
        let num = 0;
        for (const i of list) {
            copyFaceImageList[num] = {
                id: i.id,
                path: i.path,
                imageWidth: i.imageWidth,
                imageHeight: i.imageHeight,
            };
            num = num + 1;
        }
        this.setFaceImageList(copyFaceImageList);
    },

    resetFacePhotoFrameList() {
        this.setFrameList([]);
        // this.setTop([]);
        // this.setLeft([]);
        // this.setZoomFactorList([]);
    },

    // addConnectionFaceImagePathList(
    //     topVal,
    //     leftVal,
    //     widthVal,
    //     heightVal,
    //     pathVal,
    //     idx
    // ) {
    //     // console.log("numFrame Val", MetaverseContentsUiStore.numFrame);
    //     console.log(
    //         "connectFaceImagePathList",
    //         this.connectFaceImagePathList,
    //         "connectFaceImagePathList Length",
    //         this.connectFaceImagePathList.length,
    //         "previous image position length",
    //         PlayerStore.positionList[this.numFrame]?.position?.length
    //     );
    //     if (
    //         this.frameData[this.numFrame].position.length !== 0 &&
    //         PlayerStore.positionList[this.numFrame]?.position?.length !== 0
    //     ) {
    //         if (
    //             this.connectFaceImagePathList.length ===
    //             PlayerStore.positionList[this.numFrame]?.position?.length
    //         ) {
    //             this.connectFaceImagePathList[idx].top = topVal;
    //             this.connectFaceImagePathList[idx].left = leftVal;
    //             this.connectFaceImagePathList[idx].width = widthVal;
    //             this.connectFaceImagePathList[idx].height = heightVal;
    //             this.connectFaceImagePathList[idx].path = pathVal;
    //         } else {
    //             if (
    //                 this.connectFaceImagePathList.length <
    //                 PlayerStore.positionList[this.numFrame]?.position?.length
    //             ) {
    //                 console.log("faceImageState", this.faceImageState);
    //                 this.connectFaceImagePathList.push({
    //                     top: topVal,
    //                     left: leftVal,
    //                     width: widthVal,
    //                     height: heightVal,
    //                     path: pathVal,
    //                 });
    //             }
    //             // else {
    //             //     this.connectFaceImagePathList[idx].width = widthVal;
    //             //     this.connectFaceImagePathList[idx].height = heightVal;
    //             //     this.connectFaceImagePathList[idx].top = topVal;
    //             //     this.connectFaceImagePathList[idx].left = leftVal;
    //             // }
    //         }
    //     } else if (this.frameData[this.numFrame].position.length === 0) {
    //         this.setConnectFaceImagePathList([]);
    //     }
    // },

    setFrameImageList(frameImageList) {
        this.frameimageList = frameImageList;
    },

    setSelectedBirthdayMonth(selectedBirthdayMonth) {
        this.selectedBirthdayMonth = selectedBirthdayMonth;
    },

    setSelectedBirthdayPeople(selectedBirthdayPeople) {
        this.selectedBirthdayPeople = selectedBirthdayPeople;
    },

    setAllImage(allImage) {
        this.allImage = allImage;
    },

    setDeleteFacePatchListDB(deleteFacePatchListDB) {
        this.deleteFacePatchListDB = deleteFacePatchListDB;
    },

    setFacePatchListFromMemory(memory) {
        this.FacePatchListFromMemory = memory;
    },

    setFacePatchListFromDB(DB) {
        this.FacePatchListFromDB = DB;
    },

    setContentUUID(uuid) {
        this.contentUUID = uuid;
        // console.log("InputUUID: ", this.contentUUID);
    },

    setFaceImageList(list) {
        this.faceImageList = list;
    },

    setFrameData(frameData) {
        this.frameData = frameData;
    },

    setFrameList(frameList) {
        this.frameList = frameList;
        console.log('this.frameList', this.frameList);
    },

    setIdListFromDB(idListFromDB) {
        this.idListFromDB = idListFromDB;
    },

    setIsDelete(isDelete) {
        this.isDelete = isDelete;
    },

    setIsFirst(first) {
        this.isFirst = first;
    },

    setIsNewPhoto(newPhoto) {
        this.isNewPhoto = newPhoto;
    },

    setIsReady(isReady) {
        this.isReady = isReady;
    },

    setConnectFaceImagePathList(connectFaceImagePathList) {
        this.connectFaceImagePathList = connectFaceImagePathList;
    },

    setNumFrame(index) {
        this.numFrame = index;
    },

    setPaddingLeft(paddingLeft) {
        this.paddingLeft = paddingLeft;
    },

    setTop(top) {
        this.top = top;
    },

    setLeft(left) {
        this.left = left;
    },

    setZoomFactorList(zoomFactorList) {
        this.zoomFactorList = zoomFactorList;
    },

    setVideoCurrentWidth(videoCurrentWidth) {
        this.videoCurrentWidth = videoCurrentWidth;
    },

    setVideoCurrentHeight(videoCurrentHeight) {
        this.videoCurrentHeight = videoCurrentHeight;
    },

    setNextIndex(nextIndex) {
        this.nextIndex = nextIndex;
    },

    deleteIDListFromDB(ID) {
        let idx = 0;
        // console.log("beforeDeleteIDListFromDBLength: ", this.idListFromDB.length);
        for (idx = 0; idx < this.idListFromDB.length; idx++) {
            if (this.idListFromDB[idx] === ID) {
                this.idListFromDB.splice(idx, 1);
            }
        }
        // console.log("afterDeleteIDListFromDBLength: ", this.idListFromDB.length);
    },

    getFaceImages() {
        return this.faceImageList;
    },

    setState(change_to) {
        this.state = change_to;
    },

    deleteIDFromMemory(del_id) {
        try {
            delete this.FacePatchListFromMemory[del_id];
            this.isDelete = true;
        } catch {
            console.log(
                `[ERROR] ${del_id} is not found in FacePatchListFromMemory`
            );
        }
    },

    deleteIDFromDB(del_id) {
        try {
            delete this.FacePatchListFromDB[del_id];
            this.isDelete = true;
        } catch {
            console.log(
                `[ERROR] ${del_id} is not found in FacePatchListFromDB`
            );
        }
    },

    requestLikuMemory() {
        let topic = `liku/${this.contentUUID}/vis/image/metaverse`;
        let message = "memory";
        MqttStore.publish(topic, message);
    },

    async getFaceImageList() {
        //@@@@@@@@@
        let serial = getCookie("likuSerial");
        // console.log("serial", serial);
        /* 쿠키의 리쿠 시리얼이 현재 리쿠 시리얼과 일치하지 않을 때 시리얼을 넣어주는 곳이다. */
        if (LikuStore.thisLiku?.serial !== undefined) {
            if (serial !== LikuStore.thisLiku?.serial) {
                serial = LikuStore.thisLiku?.serial;
            }
        }
        let classLength = 0;
        if (KidUiStore.classUserList.length !== 0) {
            classLength = KidUiStore.classUserList.length;
        }

        await MetaverseRepository.getFaceImageList(classLength).then((r) => {
            this.setFaceImageList(r.content);
        });
        // console.log("set faceImageLisgt", this.faceImageList);
    },

    async reloadDB() {
        await this.getFaceImageList();
        // console.log("faceImageList", this.faceImageList);
        // 깊은 복사
        let copyIdList = Array.from(this.idListFromDB);
        // console.log("copyIdListFromDB", copyIdList);
        // console.log("copyIdListFromDBLength", copyIdList.length);
        // await Object.keys(this.faceImageList).map((value, index) => {

        for (const value of Object.keys(this.faceImageList)) {
            // for (const value of Object.keys(this.faceImageList)) {
            let isIncludedInDeleteFacePatchListDB = false;
            let isIncludedInFacePatchListFromDB = false;
            let isIncludedInIdlistFromMemory = false;
            // console.log("value from faceImageList $%$%", value);

            for (const delIdVal of this.deleteFacePatchListDB) {
                if (this.faceImageList[value].id === delIdVal) {
                    isIncludedInDeleteFacePatchListDB = true;
                    break;
                }
            }

            // for (let num = 0; num < this.deleteFacePatchListDB.length; num++) {
            //   if (this.faceImageList[value].id === this.deleteFacePatchListDB[num]) {
            //     isIncludedInDeleteFacePatchListDB = true;
            //     num = this.deleteFacePatchListDB.length;
            //   }
            // }

            if (!isIncludedInDeleteFacePatchListDB) {
                for (const val of Object.keys(this.FacePatchListFromDB)) {
                    if (
                        this.faceImageList[value].id ===
                        this.FacePatchListFromDB[val].id
                    ) {
                        isIncludedInFacePatchListFromDB = true;
                        break;
                    }
                }
            }

            if (
                !isIncludedInDeleteFacePatchListDB &&
                !isIncludedInFacePatchListFromDB
            ) {
                for (const key of Object.keys(this.FacePatchListFromMemory)) {
                    if (
                        this.faceImageList[value].id ===
                        this.FacePatchListFromMemory[key].id
                    ) {
                        isIncludedInIdlistFromMemory = true;
                        break;
                    }
                }

                // for (
                //   let num = 0;
                //   num < Object.keys(this.FacePatchListFromMemory).length;
                //   num++
                // ) {
                //   if (
                //     this.faceImageList[value].id ===
                //     this.FacePatchListFromMemory[
                //       Object.keys(this.FacePatchListFromMemory)[num]
                //     ].id
                //   ) {
                //     isIncludedInIdlistFromMemory = true;
                //     num = Object.keys(this.FacePatchListFromMemory).length;
                //   }
                // }
            }

            // console.log(
            //     "isIncludedInDeleteFacePatchListDB",
            //     isIncludedInDeleteFacePatchListDB
            // );
            // console.log(
            //     "isIncludedInFacePatchListFromDB",
            //     isIncludedInFacePatchListFromDB
            // );
            // console.log(
            //     "isIncludedInIdlistFromMemory",
            //     isIncludedInIdlistFromMemory
            // );

            if (
                !isIncludedInDeleteFacePatchListDB &&
                !isIncludedInFacePatchListFromDB &&
                !isIncludedInIdlistFromMemory
            ) {
                if (this.state) {
                    this.FacePatchListFromMemory[value] = {
                        id: this.faceImageList[value].id,
                        path: this.faceImageList[value].path,
                        imageWidth: this.faceImageList[value].imageWidth,
                        imageHeight: this.faceImageList[value].imageHeight,
                        category: "idListFromMemory",
                    };
                } else {
                    // console.log("value analysis", this.faceImageList[value]);
                    // console.log("value idListFromDB", this.idListFromDB);

                    let statusFromDB = false;
                    let faceId = this.faceImageList[value].id;
                    // console.log("faceId", faceId);
                    if (copyIdList.length > 0) {
                        for (
                            let idx = 0;
                            idx < this.idListFromDB.length;
                            idx++
                        ) {
                            // console.log("idx", idx);
                            // this.idListFromDB.map((val, idx) => {
                            // console.log("copyIdListFromDBId", copyIdList[idx]);
                            if (faceId === copyIdList[idx]) {
                                statusFromDB = true;
                                copyIdList.splice(idx, 1);
                            }
                            idx = this.idListFromDB.length;
                            // });
                        }
                    }
                    // console.log("statusFromDB~~~~~~", statusFromDB);
                    if (statusFromDB === true) {
                        this.FacePatchListFromDB[value] = {
                            id: this.faceImageList[value].id,
                            path: this.faceImageList[value].path,
                            imageWidth: this.faceImageList[value].imageWidth,
                            imageHeight: this.faceImageList[value].imageHeight,
                            category: "idListFromDB",
                        };
                    } else if (statusFromDB === false) {
                        this.FacePatchListFromDB[value] = {
                            id: this.faceImageList[value].id,
                            path: this.faceImageList[value].path,
                            imageWidth: this.faceImageList[value].imageWidth,
                            imageHeight: this.faceImageList[value].imageHeight,
                            category: "AddfacePatchPhotoToDB",
                        };
                    }
                    // this.FacePatchListFromDB[value] = {id: this.faceImageList[value].id,
                    // path: this.faceImageList[value].path, imageWidth: this.faceImageList[value].imageWidth,
                    // imageHeight: this.faceImageList[value].imageHeight, category: 'AddfacePatchPhotoToDB'};
                }
            }
            // });
        }
        // console.log("value idListFromDB", this.idListFromDB);
        // console.log(
        //     "AfterArrangedFacePatchListFromDB",
        //     this.FacePatchListFromDB
        // );
        this.setIsNewPhoto(true);
    },

    requestNewPhoto() {
        let topic = `liku/${this.contentUUID}/vis/image/metaverse`;
        let message = "new";
        console.log("사진찍기");
        MqttStore.publish(topic, message);
    },

    async setWholeImg(topic, data) {
        // console.log("setWholeImg: LikuUUID", PlayerLikuUiStore.thisConnectLiku.uuid);
        if (data !== null) {
            const image = URL.createObjectURL(
                new Blob([data], { type: "image/png" })
            );
            this.setAllImage(image);
            // console.log("allImage $%$%", this.allImage);
        }
        this.reloadDB();
    },

    // shuffle() {
    //   let temp = JSON.parse(JSON.stringify(this.faceImageList));
    //   this.faceImageList = {};
    //   // Object.keys(temp).sort(() => Math.random() - 0.5).map((value, index)=>{
    //   //     this.faceImageList[index] = temp[value];
    //   // });
    //   Object.keys(temp)
    //     .sort(() => Math.random() - 0.5)
    //     .forEach((value, index) => (this.faceImageList[index] = temp[value]));
    //   // console.log("finalFaceImageList", this.faceImageList);
    // },

    facePatchListCheck() {
        // console.log('facePatchListCheck----');
        // console.log("stateValue", this.state);
        if (this.isNewPhoto) {
            this.isNewPhoto = false;
        } else if (this.isDelete) {
            this.isDelete = false;
        }

        if (this.state) {
            return Object.keys(this.FacePatchListFromMemory).length === 0
                ? true
                : false;
        } else {
            return Object.keys(this.FacePatchListFromDB).length === 0
                ? true
                : false;
        }
    },

    async applyToDB() {
        console.log("this.deleteFacePatchListDB", this.deleteFacePatchListDB);
        for (let num = 0; num < this.deleteFacePatchListDB.length; num++) {
            await deleteFaceFileDB(this.deleteFacePatchListDB[num]);
            // console.log("deleting from DB...", this.deleteFacePatchListDB[num]);
        }
        this.deleteFacePatchListDB = [];

        if (this.state) {
            this.faceImageList = await JSON.parse(
                JSON.stringify(this.FacePatchListFromMemory)
            );
            console.log("MEM: ", this.faceImageList);
            for (const value of Object.keys(this.FacePatchListFromDB)) {
                // console.log("value of this.FacePatchListFromDB", value);
                await deleteFaceFileDB(this.FacePatchListFromDB[value].id);
            }
            console.log(MetaverseContentsUiStore.selectedBirthdayPeople);

            // Object.keys(this.FacePatchListFromDB).map((value, index) => {
            //   deleteFaceFileDB(this.FacePatchListFromDB[value].id);
            // });
        } else {
            this.faceImageList = await JSON.parse(
                JSON.stringify(this.FacePatchListFromDB)
            );
            for (const value of Object.keys(this.FacePatchListFromMemory)) {
                // console.log("value of this.FacePatchListFromMemory", value);
                await deleteFaceFileDB(this.FacePatchListFromMemory[value].id);
            }
            // Object.keys(this.FacePatchListFromMemory).map((value, index) => {
            //   deleteFaceFileDB(this.FacePatchListFromMemory[value].id);
            // });
        }
        // console.log(
        //     "faceImageList $%$%&&&&&&&(*****************",
        //     this.faceImageList
        // );
        this.setDeleteFacePatchListDB([]);
        this.setFacePatchListFromMemory({});
        this.setFacePatchListFromDB({});
    },

    async exit() {
        // deleteFacePatchListDB 초기화
        if (this.state) {
            Object.keys(this.FacePatchListFromDB).forEach((value, index) =>
                this.FacePatchListFromDB[value].category !== "idListFromDB"
                    ? deleteFaceFileDB(this.FacePatchListFromDB[value].id)
                    : null
            );
        } else {
            Object.keys(this.FacePatchListFromMemory).forEach((value, index) =>
                deleteFaceFileDB(this.FacePatchListFromMemory[value].id)
            );
        }
        this.setDeleteFacePatchListDB([]);
        this.setFacePatchListFromMemory({});
        this.setFacePatchListFromDB({});
        this.setIsFirst(true);
        this.resetFacePhotoFrameList();
        this.setAllImage(null);
        this.setFaceImageList([]);

        let topic = `liku/${this.contentUUID}/musical/stop`;
        let message = { mode: "run" };

        // MqttStore.publish(topic, message);
        MqttStore.publish(topic, JSON.stringify(message));
        //subscribe해제
        let metaverseUpdatedTopic = `liku/${this.contentUUID}/vis/image/database`;
        MqttStore.unSubscribe(metaverseUpdatedTopic);
        let metaverseAddedTopic = `liku/${this.contentUUID}/vis/image/metaverse/whole`;
        MqttStore.unSubscribe(metaverseAddedTopic);
        PlayerUiStore.setMetaverseModal(false);

        ////////////////
        // this.deleteFacePatchListDB = [];
        // Object.keys(this.FacePatchListFromMemory).map((value, index) => {
        //   deleteFaceFileDB(this.FacePatchListFromMemory[value].id);
        // });
        // Object.keys(this.FacePatchListFromDB).map((value, index) => {
        //   if (this.FacePatchListFromDB[value].category !== "idListFromDB") {
        //     deleteFaceFileDB(this.FacePatchListFromDB[value].id);
        //   }
        // });
        // this.FacePatchListFromMemory = {};
        // this.FacePatchListFromDB = {};
        // this.isFirst = true;
        // this.frameList = [];
        // this.allImage = null;
        // let topic = `liku/${this.contentUUID}/musical/stop`;
        // let message = { mode: "run" };
        // // MqttStore.publish(topic, message);
        // MqttStore.publish(topic, JSON.stringify(message));
        // //subscribe해제
        // let metaverseUpdatedTopic = `liku/${this.contentUUID}/vis/image/database`;
        // MqttStore.unSubscribe(metaverseUpdatedTopic);
        // let metaverseAddedTopic = `liku/${this.contentUUID}/vis/image/metaverse/whole`;
        // MqttStore.unSubscribe(metaverseAddedTopic);
    },
});
export { MetaverseContentsUiStore };
