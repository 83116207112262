import { observable } from 'mobx';
import { MqttStore } from '../../domain/MqttStore';
import { PlayerBackgroundUiStore } from './PlayerBackgroundUiStore';
import { ContentsStore } from '../../domain/ContentsStore';
import { PlayerLikuUiStore } from './PlayerLikuUiStore';
import { MetaverseContentsUiStore } from '../metaverse/MetaverseContentsUiStore';
import { PlayerStore } from '../../domain/PlayerStore';
import { ContentsControlStore } from '../../domain/ContentsControlStore';
import { PlayerRoleUiStore } from './PlayerRoleUiStore';
import { LikuStore } from '../LikuStore';
import { PlayAndEduMusicUiStore } from '../playandedu/PlayAndEduMusicUiStore';
import { InteractiveUiStore } from '../interactive/InteractiveUiStore';
import { InteractiveStore } from '../../domain/InteractiveStore';
import { KidUiStore } from '../KidUiStore';

const PlayerUiStore = observable({
	// colorList: null,
	controlButtons: null,
	isLength: window.innerWidth <= window.innerHeight * 1.44 ? true : false,
	contents: null,
	title: '',
	uuid: null,
	metaverseModal: null,
	pictureModal: null,
	birthdayModal: null,
	mode: false,

	async init(idx) {
		// this.clear();
		// setTimeout(async () => {
		PlayerLikuUiStore.initRole();
		if (LikuStore.thisLiku !== null) {
			if (
				!MqttStore.isConnect ||
				MqttStore.client === null ||
				MqttStore.heartbeatInterval === null ||
				MqttStore.heartbeatInterval === undefined ||
				!MqttStore.isHeartbeat ||
				MqttStore.heartbeat === 15
			) {
				await MqttStore.connect();
			}
			PlayerBackgroundUiStore.initBackground();
			// console.log(
			//     "ContentsStore.contentsList",
			//     ContentsStore.contentsList
			// );
			if (ContentsStore.contentsList === null) {
				await ContentsStore.getContentsList();
			}

			await this.getContents(idx);

			// console.log(
			//     "PlayAndEduMusicUiStore.status",
			//     PlayAndEduMusicUiStore.status
			// );

			// if(PlayAndEduMusicUiStore.status === false){
			if (!PlayAndEduMusicUiStore.status) {
				await PlayerLikuUiStore.getLikuUuid();
			}
		}
		// }, 1000);
	},

	clear() {
		PlayerLikuUiStore.clear();
		if (this.isMetaverse()) {
			MetaverseContentsUiStore.exit();
			KidUiStore.setSelectedThisClass('');
			KidUiStore.setNameString('');
			KidUiStore.setClassUserList([]);
			KidUiStore.setEmptyImageUserList([]);
		} else if (this.isBirthday()) {
			KidUiStore.setBirthdayListLoad(false);
			KidUiStore.setPeopleLoad(false);
			this.setBirthdayModal(false);
			KidUiStore.birthdayClear();
		}
		MetaverseContentsUiStore.setFrameImageList([]);
		MetaverseContentsUiStore.setFrameList([]);
		KidUiStore.setThisClassObject({});
		PlayerStore.clear();
		ContentsControlStore.clear();
		PlayerBackgroundUiStore.clear();
		InteractiveStore.clear();
		// PlayerLikuUiStore.initConnectTimeOut();
		// PlayerLikuUiStore.setConnectTimeOut(15);

		// ContentsControlStore.musicalSubscribe();
		// MqttStore.close();
	},

	async getContents(idx) {
		// console.log('contentsList', ContentsUiStore.contentsList);
		// let contents = ContentsStore.getContents(idx);
		// console.log('contents!!!12', contents);
		// await PlayerStore.getContents(contents[0]);
		await PlayerStore.getContents(ContentsStore.getContents(idx)[0]);
	},

	setContents(contents) {
		this.contents = contents;
		if (this.contents !== null) this.setTitle(this.contents?.title);
		else this.setTitle('');
	},

	setTitle(title) {
		this.title = title;
	},

	setUuid(uuid) {
		this.uuid = uuid;
	},

	setMode(value) {
		this.mode = value;
	},

	setIsLength(isLength) {
		this.isLength = isLength;
	},

	setMetaverseModal(modal) {
		this.metaverseModal = modal;
	},

	setPictureModal(pictureModal) {
		this.pictureModal = pictureModal;
	},

	setBirthdayModal(birthdayModal) {
		this.birthdayModal = birthdayModal;
	},

	controlFunction(id) {
		// if (paused === 'pause') PlayerBackgroundUiStore.setVideoState(false);
		// else PlayerBackgroundUiStore.setVideoState(true);

		if (this.contents !== null && !ContentsControlStore.loading) {
			let connectCheck = PlayerRoleUiStore.likuRoleCheck();
			// console.log('connectCheck', connectCheck);
			if (connectCheck) {
				switch (id) {
					case 'rewind':
					case 'fastplay':
					case 'reset':
						ContentsControlStore.contentsMove(id);
						InteractiveStore.setCurrentId(-1);
						break;
					case 'play':
						ContentsControlStore.contentsStart();
						break;
					case 'stop':
						ContentsControlStore.contentsPause();
						break;
					case 'mode':
						if (!this.mode) {
							if (document.documentElement.requestFullscreen) {
								document.documentElement.requestFullscreen();
							} else if (document.documentElement.mozRequestFullScreen) {
								/* Firefox */
								document.documentElement.mozRequestFullScreen();
							} else if (document.documentElement.webkitRequestFullscreen) {
								/* Chrome, Safari & Opera */
								document.documentElement.webkitRequestFullscreen();
							} else if (document.documentElement.msRequestFullscreen) {
								/* IE/Edge */
								document.documentElement.msRequestFullscreen();
							}
						} else {
							// 이미 전체 화면 모드이면 원래 크기로 돌아감
							if (document.exitFullscreen) {
								document.exitFullscreen();
							} else if (document.mozCancelFullScreen) {
								/* Firefox */
								document.mozCancelFullScreen();
							} else if (document.webkitExitFullscreen) {
								/* Chrome, Safari & Opera */
								document.webkitExitFullscreen();
							} else if (document.msExitFullscreen) {
								/* IE/Edge */
								document.msExitFullscreen();
							}
						}
						this.setMode(!this.mode);
						break;
					default:
						return;
					// case 'fullscreen': ContentsControlStore.contentsStart(); break
				}
			} else {
				alert('리쿠를 연결해주세요!');
			}
		}
		// else {
		//     alert('콘텐츠를 선택해주세요!');
		// }
	},

	isMetaverse() {
		return (
			this.contents?.musicalType === 'METAVERSE' ||
			this.contents?.title.includes('메타버스') ||
			this.contents?.title.includes('(가상)') ||
			this.contents?.title.includes('(메타)')
		);
	},

	isBirthday() {
		// return this.contents?.title.includes("(생일파티 테스트)");
		return (
			this.contents?.title.includes('(생일파티 테스트)') ||
			this.contents?.title.includes('(생일파티 화면전환 테스트)') ||
			this.contents?.title.includes('생일파티')
		);
	},

	isInteractive() {
		return (
			this.contents?.musicalType === 'Interactive' ||
			this.contents?.title.includes('Interactive') ||
			this.contents?.title.includes('interactive')
		);
	},
});

export { PlayerUiStore };
