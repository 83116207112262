import { observable } from 'mobx';
import { delCollectionLiku } from '../../api/LikuApi';
import { getCollectionLikuDB } from '../../api/SignApi';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';
import { MqttStore } from '../domain/MqttStore';

import { ContentsControlStore } from '../domain/ContentsControlStore';
import LikuRepository from '../../data/repository/LikuRespository';

const LikuStore = observable({
	likus: [],
	thisLiku: null,
	likuVer: null,

	volume: null,
	state: null,

	stateIng: false,

	isUpdate: false,

	setLikus(data) {
		this.likus = data;
	},

	addLikus(liku) {
		this.likus.push(liku);
	},

	likuIndex(index) {
		console.log(this.likus[index].serial);
		delCollectionLiku(this.likus[index].serial).then((r) => {
			// console.log('delCollectionLikur',r);
			this.likus = [];
			getCollectionLikuDB().then((r) => {
				for (let i = 0; i < r.length; i++) {
					this.addLikus(r[i].liku);
					// this.likus.push();
				}

				if (this.likus.length < 1) {
					this.setThisLiku(null);
					removeCookie('likuSerial', { path: '/', maxAge: 43200 });
				} else {
					this.setThisLiku(this.likus[0]);
					setCookie('likuSerial', this.likus[0]?.serial, {
						path: '/',
						maxAge: 43200,
					});
				}
			});
		});
	},

	setThisLiku(liku) {
		console.log(liku);
		if (liku) {
			const version = JSON.parse(liku?.version);
			this.thisLiku = liku;
			this.likuVer = parseInt(version.likuVer);
			console.log('this.likuVer', this.likuVer);
		}
	},

	allUnSubscribe() {
		console.log('unSub', this.thisLiku);
		Object.keys(MqttStore.heartbeat).forEach((uuid) => {
			if (this.thisLiku?.uuid !== uuid) delete MqttStore.heartbeat[uuid];
		});
		MqttStore.subscribes.forEach((topic) => {
			MqttStore.unSubscribe(topic);
		});
	},

	connectCheckAndRetry() {
		if (LikuStore.thisLiku !== null) {
			if (!MqttStore.client) MqttStore.connect();
			else if (MqttStore.client && !MqttStore.isHeartbeat) {
				this.likuStateSubscribe();
				this.likuStatePublish();
			}
		}
		// let init;
		// console.log('connectCheckAndRetry MqttStore.isConnect', !MqttStore.isConnect);
		// if (LikuStore.thisLiku !== null) {
		//     if (!MqttStore.isConnect) {
		//         init = true;
		//     } else if (MqttStore.heartbeatInterval === null || MqttStore.heartbeatInterval === undefined || !MqttStore.isHeartbeat || MqttStore.heartbeat === 15) {
		//         init = false;
		//     }
		// }
		// if (LikuStore.thisLiku !== null) {
		//     if (!MqttStore.client) {
		//         MqttStore.connect();
		//     } else if (MqttStore.client && (!MqttStore.isHeartbeat || isChange)) {
		//         this.likuStateSubscribe();
		//         this.likuStatePublish();
		//         // }, 1000);
		//         // this.mqttInit();
		//     }
		// }

		// setTimeout(() => {
		//     init !== undefined && this.mqttInit(init, isChange);
		// }, 2000);
	},
	async mqttInit(init, isChange) {
		// console.log('mqttInit', init);
		// if (init) {
		//     await MqttStore.connect().then(() => {
		//         console.log('mqttInit 1', MqttStore.client);
		//         console.log('mqttInit isConnect', MqttStore.isConnect);
		//         if (MqttStore.client !== null && MqttStore.isConnect) {
		//             console.log('mqttInit sub pub');
		//             this.likuStateSubscribe();
		//             this.likuStatePublish();
		//         }
		//     });
		// } else if (!init) {
		//     console.log('mqttInit 2', MqttStore.client);
		//     if ((MqttStore.client !== null && MqttStore.isConnect && !MqttStore.isHeartbeat) || isChange) {
		//         console.log('mqttInit 3', MqttStore.client);
		this.likuStateSubscribe();
		this.likuStatePublish();
		//     }
		// }
	},

	likuStateSubscribe() {
		if (this.thisLiku?.uuid) {
			const ackTopic = `liku/${getCookie('useEmail')}/ackCheck`;
			const heartbeatTopic = `liku/${this.thisLiku?.uuid}/heartbeat`;
			const resultTopic = `liku/${this.thisLiku?.uuid}/json/result`;
			const volumeTopic = `liku/${this.thisLiku?.uuid}/volume`;
			const motionTopic = `liku/${this.thisLiku?.uuid}/motion-status`;
			const listenTopic = `liku/${this.thisLiku?.uuid}/json/transcript`;
			const arucoTopic = `liku/${this.thisLiku?.uuid}/aruco/result`;
			MqttStore.subscribe(ackTopic);
			MqttStore.subscribe(heartbeatTopic);
			MqttStore.subscribe(resultTopic);
			MqttStore.subscribe(volumeTopic);
			MqttStore.subscribe(motionTopic);
			MqttStore.subscribe(listenTopic);
			MqttStore.subscribe(arucoTopic);
			MqttStore.setHeartBeatInterval(this.thisLiku?.uuid);

			if (this.state === 'musical' || ContentsControlStore.start)
				ContentsControlStore.musicalSubscribe();
		}
	},

	likuStatePublish() {
		this.getStateTopic();
		this.getVolumeTopic();
		console.log('reconnected liku State!!!');
		// if (MqttStore.isReconnect) {
		//   MqttStore.reSubscribe();
		// }
	},

	setLikuState(value) {
		// console.log("state", value);
		this.state = value;
		// if (message_obj.status === 'sleeping') {
		//     data = '자는중';
		// } else {
		//     data = '일어남';
		// }
	},

	setMotionStatus() {
		console.log('setMotionStatus');
		const topic = `liku/${this.thisLiku?.uuid}/motion-status/set`;
		const message = 'run';
		MqttStore.publish(topic, message);
	},

	setStateIng(value) {
		this.stateIng = value;
	},

	getStateTopic() {
		if (this.thisLiku?.uuid) {
			const topic = `liku/${this.thisLiku?.uuid}/motion-status/get`;
			const message = { message: 'data' };
			MqttStore.publish(topic, message);
		}
	},

	setStateTopic(message) {
		const topic = `liku/${this.thisLiku?.uuid}/exp`;
		// let message = null;
		// if (this.state === 'active') message = SleepJson;
		// else message = WakeUpJson;

		MqttStore.publish(topic, message);
	},

	setVolume(volume) {
		this.volume = volume.volume;
	},

	getVolumeTopic() {
		if (this.thisLiku?.uuid) {
			const topic = `liku/${this.thisLiku?.uuid}/volume/get`;
			const message = { volume: 'get' };
			MqttStore.publish(topic, message);
		}
	},

	setVolumeTopic(volume) {
		if (this.thisLiku?.uuid) {
			const topic = `liku/${this.thisLiku?.uuid}/volume/set`;
			const message = { volume: volume };
			MqttStore.publish(topic, message);
		}
	},

	async checkVersion() {
		// requestPermission({type: "Get_liku"});
		console.log('~!~!~!~!this.thisLiku', this.thisLiku);
		// const serial = this.thisLiku?.name.replace('LIKU: ', '');
		const serial = this.thisLiku?.serial;
		const data = await LikuRepository.getLikuInfo({ serial: serial });

		// this.setVersion(data.version?.likuVer);
		//
		// console.log('data?.version?.updaterVer', data?.version?.updaterVer);
		// data.version.updaterVer = "2.0";

		const isUpdate = data?.version?.updaterVer === '2.0';
		if (isUpdate) this.setIsUpdate(true);
		else this.setIsUpdate(false);
	},

	setIsUpdate(value) {
		this.isUpdate = value;
	},
});

export { LikuStore };
