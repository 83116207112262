import { observable, toJS } from "mobx";
import {
    getKidUploadFileDB,
    getAttendanceDB,
    getFaceUserDB,
    faceDetection,
    editImage,
    croppedFaceFileUpload,
} from "../../api/KidMangeApi";
import { FilterUiStore } from "./FilterUiStore";
import { KidAddUiStore } from "./KidAddUiStore";
import { getCollectionLikuDB } from "../../api/SignApi";
import { LikuStore } from "./LikuStore";
import { MqttStore } from "../domain/MqttStore";
import axios from "axios";
import { MetaverseContentsStore } from "../domain/MetaverseContentsStore";
import { MetaverseContentsUiStore } from "./metaverse/MetaverseContentsUiStore";
import MetaverseRepository from "../../data/repository/MetaverseRepository";
import { getCookie } from "../../utils/cookie";
import babyduck from "../../resource/image/picture/미운아기오리.png";
import earofking from "../../resource/image/picture/임금님귀.png";
import sampleImage from "../../resource/image/picture/sampleImage_fp_50cm.png";

const KidUiStore = observable({
    // set variables
    // 변수 설정
    kids: [],
    posts: [],
    attendanceData: [],
    classList: [],
    classUserList: [],
    classObject: {},
    landmarkList: [],
    emptyImageUserList: [],
    tempImageURL: null,
    counter: 0,
    tempImageIdx: 0,
    nameString: "",
    thisClass: "",
    faceId: "",
    filePath: "",
    isImageUpload: false,
    totalPosts: [],
    filterData: [],
    filterTotalData: [],
    faceData: null,
    videoWidth: 0,
    videoHeight: 0,
    dataNumber: 0,
    dataDoubleNumber: 0,
    totalAttendance: 0, //출석
    attendance: 0, //등교
    goHome: 0, // 하교
    acknowledgmentAbsent: 0, //인정결석
    curPage: 0,
    maxIndex: 0,
    minIndex: 100,
    firstKidIndex: "",
    selectRepresentImage: null,
    likuUUID: "",
    representList: [],
    selectedIdx: null,
    dateMonth: null,
    birthdayList: [],
    birthdayPeopleList: [],
    birthdayListLoad: false,
    birthdayListState: false,
    peopleListState: false,
    peopleLoad: false,
    curIndex: 0,
    birthdayPeopleIndex: null,
    count: 0,
    kidsListString: "",
    userState: false,
    listCount: 0,
    nameList: [],

    // year: new Date().getFullYear(),

    async setKidReLoad() {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const yesterday = today.getTime() - 86400000;
        const yesterdayString = new Date(yesterday);
        const yesterdayYear = yesterdayString.getFullYear();
        const yesterdayMonth = yesterdayString.getMonth() + 1;
        const yesterdayDay = yesterdayString.getDate();
        // await till executing saveDate function done in KidAddUiStore
        // KidAddUiStore 프로미스 안의 saveDate 함수가 실행될 때까지 기다리기
        await KidAddUiStore.saveDate(year, month, day);
        await KidAddUiStore.saveYesterDayDate(
            yesterdayYear,
            yesterdayMonth,
            yesterdayDay
        );
        // load attendanceDB and apply to attendanceData through setAttendanceData function
        // attendanceDB 불러온 후 setAttendanceData 함수를 통해 attendanceData에 적용
        await getAttendanceDB().then((r) => {
            // console.log("----getAttendanceDB", r);
            this.setAattendanceData(r);
        });

        await getFaceUserDB().then((r) => {
            // console.log("*****getFaceUserDB", r);
            this.setPosts(r);
            this.setTotalPosts();
            // console.log("FaceUserDB: ", this.totalPosts);
        });
        await FilterUiStore.setFilterBox(null);
        getCollectionLikuDB().then((r) => {
            LikuStore.setLikus([]);
            for (let i = 0; i < r.length; i++) {
                LikuStore.addLikus(r[i].liku);
                // LikuStore.likus.push(r[i].liku);
            }
            // console.log('dddddd', LikuStore.likus);
        });
    },
    // set id value to new row's first attibute
    // 새 행의 첫번째 요소에 아이디값 설정
    setFirstKidIndex(index) {
        this.firstKidIndex = index;
    },

    // async updateFilteredData() {

    //   // this.filterData = [];
    //   console.log("totalPostsVal: ", this.totalPosts);
    //   console.log('UpdatedSearchTerm: ', FilterUiStore.searchTerm);
    //   console.log('UpdatedFilterBox: ', FilterUiStore.filterBox);
    //   console.log("year: ", this.year);
    //   //
    //   console.log("filtered_data: ", this.totalPosts.filter()?.birth);

    //   this.totalPosts.filter((val) => {
    //     // console.log('updated StartfilterData: ', val);
    //     try{
    //       // if (FilterUiStore.searchTerm === "" && FilterUiStore.filterBox === null) {
    //       //   this.filterData.push(val);
    //       // } else if (FilterUiStore.filterBox === "All") {
    //       //   this.filterData.push(val);
    //       // } else if (val?.name?.includes(FilterUiStore.searchTerm)) {
    //       //   this.filterData.push(val);
    //       // } else if (val?.profile?.includes(FilterUiStore.searchTerm)) {
    //       //   this.filterData.push(val);
    //       // } else if (
    //       //     (this.year - JSON.stringify(val?.birth).slice(0, 4) + 1)
    //       //         .toString()
    //       //         .includes(FilterUiStore.searchTerm)
    //       // ) {
    //       //   this.filterData.push(val);
    //       // } else if (val?.state === FilterUiStore.filterBox) {
    //       //     // console.log("val.state", val?.state);
    //       //   this.filterData.push(val?.state);
    //       // }
    //       // console.log("UpdateFilteredData: ", this.filterData);

    //     } catch (e) {
    //       console.log('error', e);
    //     }
    //   this.filterData.push(0);
    // })},

    /*
    this.filterData = [];
    //
    this.totalPosts.filter((val) => {
      try {
          console.log('updated StartfilterData: ', val);
          // console.log('page: ', page);
          // value null
          if (FilterUiStore.searchTerm === "" && FilterUiStore.filterBox === null) {
            this.filterData.push(val);
          } else if (FilterUiStore.filterBox === "All") {
            this.filterData.push(val);
          } else if (val?.name?.includes(FilterUiStore.searchTerm)) {
            this.filterData.push(val);
          } else if (val?.profile?.includes(FilterUiStore.searchTerm)) {
            this.filterData.push(val);
          } else if (
              (this.year - JSON.stringify(val?.birth).slice(0, 4) + 1)
                  .toString()
                  .includes(FilterUiStore.searchTerm)
          ) {
            this.filterData.push(val);
          } else if (page > 1 && val?.state === FilterUiStore.filterBox) {
              // console.log("val.state", val?.state);
            this.filterData.push(val?.state);
          }
        } catch (e) {
          console.log('error', e);
        }
      console.log('updated EndFilterData: ', val);
      this.filterData.push(0);})
      */
    // },

    setNameString(nameString) {
        this.nameString = nameString;
    },

    setEmptyImageUserList(emptyImageUserList) {
        this.emptyImageUserList = emptyImageUserList;
    },

    setNameList(nameList) {
        this.nameList = nameList;
    },

    setMaxIndex(maxIndex) {
        this.maxIndex = maxIndex;
    },

    setMinIndex(minIndex) {
        this.minIndex = minIndex;
    },

    setBirthdayListState(birthdayListState) {
        this.birthdayListState = birthdayListState;
    },

    setPeopleListState(peopleListState) {
        this.peopleListState = peopleListState;
    },

    setPeopleLoad(peopleLoad) {
        this.peopleLoad = peopleLoad;
    },

    setSelectedIdx(selectedIdx) {
        this.selectedIdx = selectedIdx;
    },

    setBirthdayPeopleIndex(birthdayPeopleIndex) {
        this.birthdayPeopleIndex = birthdayPeopleIndex;
    },

    setCurrentPage(page) {
        this.curPage = page;
    },

    setVideoWidth(width) {
        this.videoWidth = width;
    },

    setVideoHeight(height) {
        this.videoHeight = height;
    },

    setTotalAttendance() {
        this.totalAttendance = this.totalAttendance + 1;
        // this.totalAttendance = this.attendance + this.acknowledgmentAbsent;
    },

    setAttendance() {
        this.attendance = this.attendance + 1;
    },

    setThisClassObject(classObject) {
        this.classObject = classObject;
    },

    // getAttendance() {
    //   return this.attendance;
    // },
    setListCount(listCount) {
        this.listCount = listCount;
    },

    increaseListCount() {
        this.listCount = this.listCount + 1;
    },

    setGoHome() {
        this.goHome = this.goHome + 1;
    },

    setAcknowledgmentAbsent() {
        this.acknowledgmentAbsent = this.acknowledgmentAbsent + 1;
    },

    setDataNumber(number) {
        this.dataNumber = number;
    },

    setAattendanceData(data) {
        this.attendanceData = data;
    },

    setSample() {
        this.totalPosts = [];
        FilterUiStore.setFilterBox("All");
    },

    increaseNumber() {
        this.dataNumber = this.dataNumber + 1;
        // console.log('this.dataNumber', this.dataNumber)
    },

    decreaseNumber() {
        this.dataNumber = this.dataNumber - 1;
    },

    increaseDoubleNumber() {
        this.dataNumber = this.dataNumber + 5;
    },

    decreaseDoubleNumber() {
        this.dataNumber = this.dataNumber - 5;
    },
    // set totalPosts
    // totalPosts 설정
    setTotalPosts() {
        // insert all attributive set values after load them
        // 불러온 후 모든 설정해준 속성 값 삽입하기
        this.totalPosts = [...this.posts];
        // console.log("totalPosts: ", this.totalPosts);

        // define local variable and set value
        // 지역 변수 정의 및 값 설정
        let subPosts = [];
        // console.log('**************', this.posts);
        // console.log('=====', this.attendanceData);
        // if value of attendanceData is null
        // attendanceData 값이 '널'일때
        console.log('attendanceData', this.attendanceData);
        if (this.attendanceData !== null) {
            // do subordinate code iterative as much as the length of attendanceData
            // attendanceData의 길이만큼 하위 코드를 반복해라
            for (let i = 0; i < this.attendanceData.length; i++) {
                // define local variable 'found' and set boolean value
                // found 변수 정의 및 boolean 값 설정 (flag처럼 동작??)
                let found = false;
                // console.log("length of posts: ", this.posts.length);
                // do subordinate code iterative as much as the length of posts
                // posts의 길이만큼 하위 코드 반복
                for (let k = 0; k < this.posts.length; k++) {
                    // console.log("Posts1: ", this.posts[k]);
                    // console.log("beforeInitializeID: ", this.posts[k].attendanceId);

                    this.posts[k].attendanceId = 0;
                    if (
                        this.posts[k].id === this.attendanceData[i].faceUser.id
                    ) {
                        // console.log("postID: ", this.posts[k].id);
                        // console.log("AttendanceDataID: ", this.attendanceData[i].faceUser.id);
                        this.posts[k].attendanceId = this.attendanceData[i].id;
                        const merge = Object.assign(
                            this.attendanceData[i],
                            this.posts[k]
                        );
                        // console.log("MergedData: ", merge);
                        // console.log("beforeTotalPosts: ", this.totalPosts[k]);
                        this.totalPosts[k] = merge;
                        // console.log("afterTotalPosts: ", this.totalPosts[k]);
                        this.totalPosts[k].state = merge.state;
                        found = true;
                        break;
                    } else {
                        // console.log("previousDataLoad: ", this.attendanceData[i]);
                        subPosts.splice(this.attendanceData[i]);
                    }
                }
                if (found === false) {
                    subPosts.push(i);
                }
            }
        }
        for (let j = 0; j < subPosts.length; j++) {
            // console.log('for in');
            // this.totalPosts = [];
            // console.log("subPostsValue: ", this.attendanceData[subPosts[j]]);
            // console.log("before totalPosts: ", this.totalPosts);
            this.totalPosts.push(this.attendanceData[subPosts[j]]);
            // console.log("after totalPosts: ", this.totalPosts);
        }

        // console.log('beforeTotaltotalPosts', this.totalPosts);
        // filter if r.kidId is undefined or null and sort list in order
        // r.kidID가 정의되어 있지 않거나 null이면 필터링 하고 순서대로 리스트 정렬
        this.totalPosts = this.totalPosts.filter(
            (r) => r.kidId !== undefined || r.kidId !== null
        );

        for (let i = 0; i < this.totalPosts.length; i++) {
            if (this.totalPosts[i].state === "ATTENDANCE") {
                this.totalPosts[i].state = "출석";
            } else if (this.totalPosts[i].state === "SICK") {
                this.totalPosts[i].state = "병가";
            } else if (this.totalPosts[i].state === "ACCIDENT") {
                this.totalPosts[i].state = "사고";
            } else if (this.totalPosts[i].state === "ADMISSION") {
                this.totalPosts[i].state = "입소";
            } else if (this.totalPosts[i].state === "EXIT") {
                this.totalPosts[i].state = "퇴소";
            } else if (
                this.totalPosts[i].state === "ABSENCE" ||
                this.totalPosts[i].state === undefined ||
                this.totalPosts[i].state === null
            ) {
                this.totalPosts[i].state = "결석";
            }
        }

        console.log('afterTotaltotalPosts', this.totalPosts);

        this.checkAttendance();
        // this.setFilterTotalData(this.totalPosts);
        // console.log('setFilterTotalData', this.filterTotalData);
        // 이름기준으로 totalPosts정렬한 후 새로운 attendanceId 부여
        let sortdata = this.totalPosts.sort(function (a, b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
        // console.log('SortData: ', sortdata);
        this.setFilterTotalData(sortdata);
        // KidUiStore.setFilterTotalData(sortdata);
    },

    // add id to new row for filtering
    // 필터링 위해서 새 행에 id값 부여
    async setFilterTotalData(data) {
        // console.log('beforeFilterTotladata', this.filterTotalData);
        this.filterTotalData = data;
        // console.log('FilterTotladata', this.filterTotalData);
        // console.log("입력된 이름: ", KidAddUiStore.firstAddIndex);
        // loop repetition up to the length of total data rows
        // 총 데이터 행의 길이만큼 루프 반복
        // for(let i=0; i<this.filterTotalData.length;  i++) {
        //   console.log('원아 이름', this.filterTotalData[i].name);
        //   console.log('인덱스: ', KidAddUiStore.firstAddIndex);

        //   // grant id value if filterTotalData row's name matches with assigned name
        //   // 어떤 행의 이름이랑 친구 추가시 할당해준 이름 비교해서 일치하면 id값 부여
        //   if (this.filterTotalData[i].name === KidAddUiStore.firstAddIndex) {
        //     this.setFirstKidIndex(this.filterTotalData[i].id);
        //     // console.log('--', this.firstKidIndex);
        //     // getKidUploadFileDB(this.firstKidIndex).then(r => console.log('결과값', r));
        //   }
        // }
    },

    setKids(data) {
        this.kids = data;
        // console.log("kidskidskids!!!", toJS(this.kids));
    },

    setPosts(data) {
        this.posts = data;
        // console.log("LoadedKidsList", toJS(this.posts));
    },

    /* 초기화 하거나 삭제 후 리스트 채울 때 공간 만들기 위해 사용 */
    addBirthdayPeople() {
        if (this.peopleLoad === false && this.birthdayListLoad === false) {
            // this.curIndex === null
            this.birthdayPeopleList.push({
                name: "이름",
                id: 0,
                image: null,
            });
        }
    },

    /**
     * 반 별로 선택한 사람들 생일자 리스트에 넣기
     */
    addBirthdayPeopleList() {
        let countIdx = 0;
        this.birthdayList.map((value, index) => {
            // console.log(
            //   "value option",
            //   value.option,
            //   ", index",
            //   index,
            //   "birthdayPeopleList",
            //   this.birthdayPeopleList,
            //   "birthdayPeopleListLength",
            //   this.birthdayPeopleList?.length
            // );
            /*  선택한 사람들 옵션 버튼이 true일때
             *   생일자 리스트 길이가 0이면
             *   생일자 리스트를 넣고
             *   생일자 리스트 길이가 0이 아니면
             *   생일자 리스트 중 선택한 원아가 있는지 확인
             *   있으면 '이미 등록된 원아입니다.'라고 알림 표시
             *   없으면 해당 인덱스에 값 넣어줌
             */
            if (value.option === true) {
                if (this.birthdayPeopleList?.length === 0) {
                    this.birthdayPeopleList.push({
                        option: true,
                        name: value.name,
                        id: value.id,
                        image: value.image,
                    });
                } else if (this.birthdayPeopleList?.length !== 0) {
                    let imageState = false;
                    for (const val of this.birthdayPeopleList) {
                        if (val.name === value.name) {
                            imageState = true;
                            break;
                        }
                    }
                    if (imageState === false) {
                        let birthdayPeopleListState = false;
                        if (countIdx < this.birthdayPeopleList?.length) {
                            for (const [
                                index,
                                value,
                            ] of this.birthdayPeopleList?.entries()) {
                                if (
                                    this.birthdayPeopleList[index]?.image ===
                                    null
                                ) {
                                    birthdayPeopleListState = true;
                                    countIdx = index;
                                    break;
                                }
                            }
                            if (value.image !== null) {
                                if (birthdayPeopleListState === true) {
                                    // console.log("value.image", value.image);
                                    this.birthdayPeopleList[countIdx].option =
                                        value.option;
                                    this.birthdayPeopleList[countIdx].name =
                                        value.name;
                                    this.birthdayPeopleList[countIdx].id =
                                        value.id;
                                    this.birthdayPeopleList[countIdx].image =
                                        value.image;
                                } else if (birthdayPeopleListState === false) {
                                    this.birthdayPeopleList.push({
                                        option: true,
                                        name: value.name,
                                        id: value.id,
                                        image: value.image,
                                    });
                                }
                            } else {
                                // alert(
                                //     `${value.name} 원아의 사진이 없습니다. 사진 등록해주세요!!`
                                // );
                            }
                        } else {
                            // countIdx >= this.birthdayPeopleList?.length
                        }
                    } else {
                        alert("이미 등록된 원아입니다!!");
                    }
                }
            }
        });
        // console.log("BirthdayPeopleList", this.birthdayPeopleList);
        // console.log(
        //     "BirthdayPeopleList Length",
        //     this.birthdayPeopleList?.length
        // );
    },

    // deleteBirthdayPeople(del_id) {
    //     // console.log("del_id @@#$@#$@$#@%#@^@$", del_id);
    //     try {
    //         const copyBirthdayPeopleList = this.birthdayPeopleList;
    //         copyBirthdayPeopleList.splice(del_id, 1);
    //         this.setBirthdayPeopleList(copyBirthdayPeopleList);
    //     } catch {
    //         console.log(`[ERROR] ${del_id} is not found in birthdayPeopleList`);
    //     }
    // },

    /**
     * 생일자 리스트에서
     * 옵션 상태가 true인 생일자 삭제
     */
    deleteBirthdayPeopleList() {
        let copyBirthdayPeopleList = [...this.birthdayPeopleList];
        const tempBirthdayPeopleList = copyBirthdayPeopleList.filter(
            (item) => item.option === false
        );
        let length = 5 - tempBirthdayPeopleList.length;
        this.setBirthdayPeopleList(tempBirthdayPeopleList);
        for (let i = 0; i < length; i++) {
            this.addBirthdayPeople();
        }
    },

    async setKidList() {
        await getFaceUserDB().then((r) => {
            // console.log("KidList", r);
            this.setKids(r);
            FilterUiStore.setFilterBox("All");
        });
    },

    /**
     * 반별 원아 리스트 정보 가져와서 객체 형태로 저장
     */
    async setClassObject() {
        if (this.kids.length === 0) {
            await this.setKidList();
        }
        // 등록된 원아가 없다면
        if (this.kids?.length === 0) {
            alert("등록된 원아가 없습니다. 원아를 등록해주세요!!");
        } else {
            // 등록된 원아가 있다면 this.kids?.length !== 0
            for (const value of this.kids) {
                if (Object.keys(this.classObject).length === 0) {
                    this.classObject[value.profile] = [value];
                } else {
                    if (this.classObject[value.profile] === undefined) {
                        this.classObject[value.profile] = [value];
                    } else {
                        this.classObject[value.profile]?.push(value);
                    }
                }
            }
            console.log("classObject", this.classObject);
        }
    },

    /**
     * 원아 리스트 중 반 리스트 추출해서 설정
     */
    async setClassList() {
        if (this.kids.length === 0) {
            await this.setKidList();
        }

        for (const value of this.kids) {
            let classStatus = false;
            if (this.classList.length === 0) {
                this.classList.push(value.profile);
            } else {
                // // method1
                // for (let i = 0; i < this.classList.length; i++) {
                //   // console.log(this.class[i]);
                //   if (value.profile === this.classList[i]) {
                //     classStatus = true;
                //     i = this.classList.length;
                //   }
                // }
                // method2
                for (const val of this.classList) {
                    if (value.profile === val) {
                        classStatus = true;
                        break;
                    }
                }
                if (classStatus === false) {
                    this.classList.push(value.profile);
                }
            }
        }
        // console.log("this.classList!!!", toJS(this.classList));
    },

    setClassUserList(classUserList) {
        this.classUserList = classUserList;
    },
    /**
     * 반 선택 리스트 중 선택한 반 설정
     * @param {string} thisClass - 선택한 반
     */
    setSelectedThisClass(thisClass) {
        this.thisClass = thisClass;
    },

    /**
     * 선택한 반이 있을 때 이 요소를 사용해 url을 파일로 변환하고 crop과 rotate처리를 해준
     * 원아의 사진과 이름을 가져오고 등록된 얼굴 사진이 없는 원아를 알려주기 위해
     * 얼굴 사진이 없는 원아 리스트를 reduce 메서드를 사용해 하나의 문자열로 만들어준다.
     * @param {string} thisClass -  선택한 반
     */
    async setThisClass(thisClass) {
        // console.log("thisClass parameter", thisClass);
        // console.log("classUserList Length", KidUiStore.classUserList?.length);
        this.setSelectedThisClass(thisClass);
        /* 선택한 반 리스트 초기화 */
        this.setClassUserList([]);
        /* 등록된 얼굴이 없는 원아 리스트 초기화 */
        this.setEmptyImageUserList([]);

        this.counter = 0;
        // this.nameString = this.thisClass + "반의 미등록/부적합 사진 원아: ";
        // console.log("this.nameString~~!!!", this.nameString);

        /*  원아의 반 속성값과 현재 반(thisClass)값이 일치하면
         *  원아 id와 이름으로 원아의 등록된 s3 image url을 파일로 변환하고
         *  crop과 rotate처리를 해준다.
         *  그리고 등록된 얼굴이 없는 원아 리스트도 반복문을 돌면서 체크해 채워준다.
         */
        this.setListCount(0);
        let tempCount = 0,
            previousName = "",
            previousId = 0;
        await this.classObject[this.thisClass]?.map(async (value, index) => {
            if (previousName !== value.name && previousId !== value.id) {
                // console.log("value", value, "index", index);
                await this.setConvertURLtoFile(value.id, value.name);
                previousName = value.name;
                previousId = value.id;
            }

            const uniqueEmptyImageUserList = [
                ...new Set(this.emptyImageUserList),
            ];

            this.setEmptyImageUserList(uniqueEmptyImageUserList);

            // console.log("tempCount $%$%", tempCount);
            // console.log(
            //     "emptyImageUserList after set classUserList",
            //     this.emptyImageUserList
            // );

            if (this.emptyImageUserList.length !== 0) {
                this.setNameString(
                    this.thisClass + "반의 미등록/부적합 사진 원아: "
                );
                let result = this.emptyImageUserList?.reduce((acc, curVal) => {
                    // console.log("acc", acc, "curVal", curVal);
                    return acc + ", " + curVal;
                });
                // console.log("result", result);
                this.setNameString(this.nameString + result);
            } else {
                this.setNameString(
                    this.thisClass + "반의 미등록/부적합 사진 원아: 없음"
                );
            }
        });

        // for (const value of this.kids) {
        //     if (value.profile === thisClass) {
        //         await this.setConvertURLtoFile(value.id, value.name);
        //     }
        // }
        // console.log(
        //     "emptyImageUserList after set classUserList outside object",
        //     this.emptyImageUserList
        // );

        /* 등록된 얼굴이 없는 원아 리스트가 있을 때 배열의 요소를 결합해 하나의 값으로
         * 만들어주는 reduce 메서드를 사용해 하나의 문자열로 만들어준다.
         */

        // console.log("this.nameString~~@$$$$$$$$$$$!!!", this.nameString);
    },

    setSelect(idx, click) {
        // console.log("index parameter", idx, "click parameter", click);
        this.classUserList[idx].select = click === true ? false : true;
    },

    /**옵션 상태 바꿔주는 함수
     * 해당 인덱스의 옵션 상태가 true면 false를 넣고
     * 해당 인덱스의 옵션 상태가 false면 true를 넣는다.
     * @param {string} idx - 해당 인덱스
     * @param {boolean} click - 클릭 상태
     */
    setBirthdayOption(idx, click) {
        this.birthdayList[idx].option = click === true ? false : true;
    },

    setBirthdayPeopleOption(idx, click) {
        this.birthdayPeopleList[idx].option = click === true ? false : true;
    },

    /**
     * S3 image URL 링크를 로컬 이미지 파일로 변환
     * 그리고 AWS face detection에 보냈던 정보를 통해
     * @param {string} id - 원아 아이디
     * @param {string} name - 원아 이름
     */
    async setConvertURLtoFile(id, name) {
        // console.log("id", id, "name", name);
        let imageURLList = [];
        /* 원아 아이디로 원아별 등록 사진 리스트를 가져올 수 있도록 백엔드 API에 요청 */
        await getKidUploadFileDB(id).then((r) => {
            imageURLList = r;
        });
        let file = null;
        let faceDetectionData;
        try {
            // console.log("name", name, "imageURLList", imageURLList.length);
            /* 등록된 원아 사진이 없을 때 */
            if (imageURLList.length === 0) {
                this.emptyImageUserList.push(name);
                this.classUserList.push({
                    select: false,
                    userName: name,
                    croppedFile: "",
                });
                /* 등록된 원아 사진이 있을 때 */
            } else {
                let count = 0;
                let state = false;
                for (const value of imageURLList) {
                    /*
                    등록된 원아 사진 리스트만큼 반복문 돌면서 s3 image url을 이미지 file로 변환
                    blob은 Binary Large Object 약어; 이진 데이터
                     */
                    const response = await axios({
                        url: `${value.fileName}`,
                        method: "GET",
                        responseType: "blob",
                        withCredentials: false,
                    });
                    // console.log("response", response);
                    // convert blob to file
                    file = new File([response.data], "faceImage.png", {
                        type: "image/png",
                    });
                    // console.log("file", file);
                    /* AWS 얼굴 감지 API에 url에서 파일로 변환된 이미지를 보내
                     * 얼굴 위치 정보와 추출된 특징(feature extraction)에 관한 정보를 가져와서
                     * faceDetectionData 변수에 저장해준다.
                     */
                    try {
                        faceDetectionData = await faceDetection(file);
                    } catch (error) {
                        console.log("error", error);
                    }
                    // console.log("faceDetectionData", faceDetectionData);
                    /** 가져온 정보를 바탕으로 이미지의 여러 얼굴 부분 중
                     *  어떤 얼굴 부분이 가장 큰 사이즈인지 판별해준다.
                     */
                    const YAW_limit = 25;
                    const PITCH_limit = 20;
                    count = count + 1;
                    let biggestFaceSize =
                        faceDetectionData.data[0].boundingBox.width *
                        faceDetectionData.data[0].boundingBox.height;
                    this.tempImageIdx = 0;
                    faceDetectionData.data?.map((value, index) => {
                        let checkSize =
                            value.boundingBox.width * value.boundingBox.height;
                        if (checkSize > biggestFaceSize) {
                            biggestFaceSize = checkSize;
                            this.tempImageIdx = index;
                        }
                    });
                    // console.log("bigIdx", this.tempImageIdx);
                    /**
                     * 선택한 얼굴 부분의 x축, z축 회전이 제한한 값에 속하는지 체크해준다.
                     */
                    if (
                        Math.abs(
                            faceDetectionData.data[this.tempImageIdx].pose.yaw
                        ) < YAW_limit &&
                        Math.abs(
                            faceDetectionData.data[this.tempImageIdx].pose.pitch
                        ) < PITCH_limit
                    ) {
                        state = true;
                        break;
                    } else {
                        // check if the face is rotated too much
                        // console.log(
                        //   "pose.yaw",
                        //   faceDetectionData.data[this.tempImageIdx].pose.yaw
                        // );
                        // console.log(
                        //   "pose.pitch",
                        //   faceDetectionData.data[this.tempImageIdx].pose.pitch
                        // );
                        continue;
                    }
                }
                // console.log("count!!", count);
                /* 선택한 얼굴 부분의 x축, z축 회전이 제한한 값에 속하면
                 * 리소스를 가리키는 url 생성 후
                 * 얼굴 부분 자르기와 회전 처리를 해주고
                 * 반별 원아 리스트에 넣어준다.
                 */
                if (state === false) {
                    this.classUserList.push({
                        select: false,
                        userName: name,
                        croppedFile: "",
                    });
                    this.emptyImageUserList.push(name);
                } else if (state === true) {
                    /* URL라이브러리의 createObjectUrl함수 통해
                     *  파일 타입이 string이면 - 블롭(이진 데이터) 같은 리소스를 가리키는 url 생성
                     *  파일 타입이 string이 아니라면 - 파일 리소스를 가리키는 url 생성
                     */
                    // console.log("file type", typeof file);
                    if (typeof file === "string") {
                        this.tempImageURL = URL.createObjectURL(
                            new Blob([file]),
                            {
                                type: "image/png",
                            }
                        );
                    } else {
                        this.tempImageURL = URL.createObjectURL(file);
                    }
                    // console.log("urlInternal", this.tempImageURL);
                    /**
                     * 얼굴 부분 자르기와 자른 얼굴 회전 처리
                     */
                    let cropResult = await editImage(
                        this.tempImageURL,
                        faceDetectionData?.data[this.tempImageIdx]
                    );
                    /* 자르기와 회전 처리해준 이미지를 반 원아 리스트에 넣어준다. */
                    this.classUserList.push({
                        select: true,
                        userName: name,
                        croppedFile: URL.createObjectURL(
                            new Blob([cropResult]),
                            {
                                type: "image/png",
                            }
                        ),
                    });

                    // const uniqueNames = new Set();
                    // // let copyBirthdayList = [...KidUiStore.birthdayList];

                    // const newClassUserList = this.classUserList.filter(
                    //     (item) => {
                    //         if (!uniqueNames.has(item.name)) {
                    //             uniqueNames.add(item.name);
                    //             return true;
                    //         }
                    //         return false;
                    //     }
                    // );
                    // // console.log("newBirthdayList", newBirthdayList);

                    // this.setClassUserList(newClassUserList);

                    /////////////////
                }
            }
            // console.log("this.classUserList!!after!!", this.classUserList);
            // console.log("emptyImageUserList", this.emptyImageUserList);
        } catch (error) {
            console.error("error happened during download image", error);
        }
    },

    /**
     * s3 리쿠 시리얼 폴더에 이미지 파일 업로드
     */
    async imageUpload() {
        // console.log("classUserList", this.classUserList);
        let likuSerial = getCookie("likuSerial");
        // console.log("LikuSerial", likuSerial);
        /* 쿠키에 리쿠 시리얼이 있는지 확인한 후 null 이 아니면
        이미지(자르기와 회전 처리된) 파일 업로드
         */
        let userDuplicate = false;
        if (likuSerial !== null) {
            for (const value of this.classUserList) {
                userDuplicate = false;
                if (value.select === true) {
                    // for (let i = 0; i < KidUiStore.nameList.length; i++) {
                    //     if (KidUiStore.nameList[i] === value.userName) {
                    //         userDuplicate = true;
                    //         break;
                    //     } else {
                    //         continue;
                    //     }
                    // }
                    // if (userDuplicate === false) {
                    KidUiStore.nameList.push(value.userName);
                    const response = await fetch(value.croppedFile);
                    const croppedBlob = await response.blob();
                    await croppedFaceFileUpload(croppedBlob, likuSerial);
                    // } else {
                    //     alert("이미 등록된 원아입니다.");
                    // }
                    // console.log(
                    //     "url",
                    //     value.croppedFile,
                    //     "name",
                    //     value.userName
                    // );
                }
            }
            this.isImageUpload = true;
        } else {
            alert("Liku를 먼저 등록해주세요!!!");
        }
        // await MetaverseContentsUiStore.reloadDB();
        // this.setClassUserList([]);

        // MetaverseContentsUiStore.reloadDB();
    },

    setCurIndex(curIndex) {
        this.curIndex = curIndex;
    },

    setCount(count) {
        this.count = count;
    },

    setLikuUUID(likuUUID) {
        this.likuUUID = likuUUID;
    },

    setSelectRepresentImage(selectRepresentImageTemp) {
        this.selectRepresentImage = selectRepresentImageTemp;
    },

    setRepresentSelect(idx, click) {
        this.representList[idx].option = click === true ? false : true;
    },

    addRepresentList(representValue) {
        this.representList.push(representValue);
    },

    setRepresentList(representList) {
        this.representList = representList;
    },

    setBirthdayList(birthdayList) {
        this.birthdayList = birthdayList;
    },

    setBirthdayPeopleList(birthdayPeopleList) {
        this.birthdayPeopleList = birthdayPeopleList;
    },

    setKidsListString(kidsListStirng) {
        this.kidsListString = kidsListStirng;
    },

    setBirthdayListLoad(birthdayListLoad) {
        this.birthdayListLoad = birthdayListLoad;
    },

    addOptionAtRepresentList(boolean, index) {
        this.representList[index].option = boolean;
    },

    // async setDefaultOption(length) {
    //   console.log("I'm here!!!!%%%%#@#$#@$@$@#$!@!!!   length", length);
    //   console.log('birthdayList', this.birthdayList);
    //   console.log('birthdayList', this.birthdayList[1]);

    //   // for (let i = 0; i < length; i++) {
    //   //   this.birthdayList[i].option = true;
    //   // }

    //   await this.birthdayList?.map((value, index) => {
    //     console.log('current birthdayList value', value, ', index', index);
    //     if (index < length) {
    //       value.option = true;
    //     }
    //   });
    // },

    /**
     * <생일파티 초기 설정>
     * 반별 원아 정보(객체) 설정
     * 현재 달 설정
     * 생일축하자 정보 리스트 초기화
     * 생일축하자 정보 리스트 공간 5개 설정(점선과 같이)
     */
    birthdayInit() {
        this.setClassObject();
        this.dateMonth = new Date()?.getMonth();
        this.setBirthdayPeopleList([]);
        for (let i = 0; i < 5; i++) {
            this.addBirthdayPeople();
        }
    },

    /**
     *  <생일파티 요소 초기화>
     *  반 별 선택 리스트 초기화
     *  선택된 반 초기화
     *  반 생일자 불러오기 상태면
     *  선택된 생일자 리스트 초
     *  반 별 불러오기 상태면
     *  현재 인덱스 초기화
     */
    birthdayClear() {
        this.setBirthdayList([]);
        this.setSelectedThisClass("");
        if (this.birthdayListLoad) {
            this.setBirthdayListState(false);
            this.setCount(0);
        } else if (this.peopleLoad) {
            this.setCurIndex(0);
        }
    },

    async setBirthdayThisClass() {
        this.setBirthdayList([]);
        this.setListCount(0);
        // console.log("birthdayPeopleList", this.birthdayPeopleList?.length);
        if (this.birthdayPeopleList?.length !== 0) {
            for (const value of this.birthdayPeopleList) {
                // console.log("imageType", typeof value.image);
                if (value.image !== null) {
                    this.setCount(this.count + 1);
                }
            }
        }
        if (this.birthdayListState === true) {
            await await this.classObject[this.thisClass]?.map(
                async (value, index) => {
                    let birthMonth = value.birth.toString().trim();
                    birthMonth = parseInt(birthMonth.substring(4, 6)) - 1;
                    if (birthMonth === this.dateMonth) {
                        this.increaseListCount();
                    }
                }
            );
        }

        let tempCount = 0,
            previousName = "";
        await this.classObject[this.thisClass]?.map(async (value, index) => {
            if (this.birthdayListState === true) {
                // console.log("tempCount $%$%", tempCount, "count", this.count);
                // console.log("birthdayListState ~~$%$%");
                let birthMonth = value.birth.toString().trim();
                birthMonth = parseInt(birthMonth.substring(4, 6)) - 1;
                if (birthMonth === this.dateMonth) {
                    if (previousName !== value.name) {
                        let imagePath = await this.setImageLoad(value.id);
                        if (imagePath !== null) {
                            imagePath = URL.createObjectURL(
                                new Blob([imagePath]),
                                {
                                    type: "image/png",
                                }
                            );
                        }
                        // console.log("birthdayList input continue ~~$%$%");
                        if (tempCount < 5 - this.count) {
                            // console.log("birthdayList option True!!!!");
                            this.birthdayList.push({
                                option: true,
                                name: value.name,
                                id: value.id,
                                image: imagePath,
                            });
                            tempCount = tempCount + 1;
                        } else {
                            // if (tempCount >= 5 - this.count)
                            // console.log("birthdayList option False!!!!");
                            this.birthdayList.push({
                                option: false,
                                name: value.name,
                                id: value.id,
                                image: imagePath,
                            });
                        }
                        previousName = value.name;
                    }
                }
                // console.log("result tempCount", tempCount);
            } else if (this.peopleListState === true) {
                // console.log("tempCount at peopleListState", tempCount);
                // console.log("peopleListState ~~$%$%");
                // console.log("index", index);
                if (previousName !== value.name) {
                    let imagePath = await this.setImageLoad(value.id);
                    if (imagePath !== null) {
                        imagePath = URL.createObjectURL(new Blob([imagePath]), {
                            type: "image/png",
                        });
                    }
                    // console.log("this.curIndex", this.curIndex);
                    if (imagePath !== null) {
                        if (tempCount < 1) {
                            // this.setCurIndex(index);
                            // console.log("birthdayList option True!!!!");
                            this.birthdayList.push({
                                option: true,
                                name: value.name,
                                id: value.id,
                                image: imagePath,
                            });
                            tempCount = tempCount + 1;
                        } else {
                            // console.log("birthdayList option False!!!!");
                            this.birthdayList.push({
                                option: false,
                                name: value.name,
                                id: value.id,
                                image: imagePath,
                            });
                        }
                    } else {
                        // this.curIndex !== null
                        this.birthdayList.push({
                            option: false,
                            name: value.name,
                            id: value.id,
                            image: imagePath,
                        });
                    }
                    previousName = value.name;
                }
            }
            // console.log("tempCount $%$%", tempCount);
        });

        // console.log("birthdayList", this.birthdayList);
    },

    async setImageLoad(faceUserId) {
        let imageURLList = [];
        await getKidUploadFileDB(faceUserId).then((r) => {
            imageURLList = r;
        });
        // console.log("imageURLList", imageURLList);
        let file = null;
        let faceDetectionData;
        try {
            let count = 0,
                state = false;
            for (const value of imageURLList) {
                const response = await axios({
                    url: `${value.fileName}`,
                    method: "GET",
                    responseType: "blob",
                    withCredentials: false,
                });
                file = new File([response.data], "faceImage.png", {
                    type: "image/png",
                });
                try {
                    faceDetectionData = await faceDetection(file);
                } catch (error) {
                    console.log("error", error);
                }
                const YAW_limit = 25;
                const PITCH_limit = 20;
                count = count + 1;
                let biggestFaceSize =
                    faceDetectionData.data[0].boundingBox.width *
                    faceDetectionData.data[0].boundingBox.height;
                this.tempImageIdx = 0;
                faceDetectionData.data?.map((value, index) => {
                    let checkSize =
                        value.boundingBox.width * value.boundingBox.height;
                    if (checkSize > biggestFaceSize) {
                        biggestFaceSize = checkSize;
                        this.tempImageIdx = index;
                    }
                });
                if (
                    Math.abs(
                        faceDetectionData.data[this.tempImageIdx].pose.yaw
                    ) < YAW_limit &&
                    Math.abs(
                        faceDetectionData.data[this.tempImageIdx].pose.pitch
                    ) < PITCH_limit
                ) {
                    state = true;
                    break;
                } else {
                    continue;
                }
            }
            if (state === false) {
                // console.log("적합한 얼굴 추출 사진이 없습니다.");
                this.tempImageURL = null;
                return this.tempImageURL;
            } else {
                // state === true
                if (typeof file === "string") {
                    this.tempImageURL = URL.createObjectURL(new Blob([file]));
                } else {
                    this.tempImageURL = URL.createObjectURL(file);
                }
                // console.log("tempImageURL @@@#$@$#@$@$#@$@", this.tempImageURL);

                let cropResultOne = await editImage(
                    this.tempImageURL,
                    faceDetectionData?.data[this.tempImageIdx]
                );
                return cropResultOne;
                // }
                // console.log("birthdayList", this.birthdayList);
            }
        } catch (error) {
            console.error("error happened during download image", error);
        }
    },

    async setRepresentThisClass(thisClass) {
        this.thisClass = thisClass;
        this.setRepresentList([]);
        // console.log("kidsList", this.kids);

        this.classObject[this.thisClass]?.map((value) => {
            this.addRepresentList({
                option: false,
                name: value.name,
                image: null,
            });
        });

        // for (const value of this.kids) {
        //   let representState = false;
        //   if (value.profile === thisClass) {
        //     if (this.representList?.length !== 0) {
        //       for (const representValue of this.representList) {
        //         if (representValue.name === value.name) {
        //           representState = true;
        //           break;
        //         }
        //       }
        //     }
        //     // if (representState === false) {
        //     this.addRepresentList({
        //       option: false,
        //       name: value.name,
        //       image: null,
        //     });
        //     // }
        //   }
        // }
        // this.representList[0].image = sampleImage;
        // this.representList[1].image = earofking;
        // this.representList[2].image = babyduck;

        // console.log('representList', this.representList);
        // let now = new Date();
        // for (const value of this.kids) {
        //   if (value.profile === thisClass) {
        //     let birthMonth = value.birth;
        //     birthMonth = birthMonth.toString().trim();
        //     birthMonth = parseInt(birthMonth.substring(4, 6)) - 1;
        //     console.log('birthMonth', birthMonth);
        //     console.log('thisMonth', now.getMonth());
        //     if (birthMonth === now.getMonth()) {
        //       console.log(
        //         'kindergarten class name',
        //         value.name,
        //         ', kindergarten class birthMonth and type',
        //         birthMonth,
        //         ', ',
        //         typeof birthMonth
        //       );
        //     }
        //   }
        //   console.log('representList', this.representList);
        // }
    },

    async rePresentInit() {
        // 전체사진찍기 mqtt 등록
        const RepresentAddedTopic = `liku/${this.likuUUID}/vision/image/user`;
        await MqttStore.subscribe(RepresentAddedTopic);
    },
    /**
     * 사진 찍기 요청
     */
    requestNewRepresentPhoto() {
        let topic = `liku/${this.likuUUID}/vis/image/wholeImg`;
        let message = "wholeImg";
        console.log("전체사진찍기");
        MqttStore.publish(topic, message);
    },

    async setRepresentImage(topic, data) {
        // console.log("data", data);
        // console.log("data type", typeof data);
        if (data !== null) {
            const representImage = URL.createObjectURL(
                new Blob([data], { type: "image/png" })
            );
            // console.log('representImage $%$%', representImage);
            this.setSelectRepresentImage(representImage);
            // console.log('selectRepresentImage $%$%', this.selectRepresentImage);
            for (const value of this.representList) {
                // console.log("value $%$%", value);
                if (value.option === true) {
                    value.image = representImage;
                }
            }
            // console.log('added whole image in representList', this.representList);
        }
    },

    async representExit() {
        // 전체사진찍기 mqtt subscribe 해제
        const RepresentAddedTopic = `liku/${this.likuUUID}/vision/image/user`;
        await MqttStore.unSubscribe(RepresentAddedTopic);
    },

    checkAttendance() {
        this.attendance = 0;
        this.totalAttendance = 0;
        this.goHome = 0;
        // console.log("---------------------");
        // console.log("원아총수: ", this.totalPosts.length);

        for (let i = 0; i < this.totalPosts.length; i++) {
            // let startTime = (this.totalPosts[i].startTime !== undefined) && this.totalPosts[i].startTime.join('');
            // let startTimeSlice = (this.totalPosts[i].startTime !== undefined) && startTime.slice(-2);
            // console.log("등교: ", startTime);
            // console.log("등교slice: ", startTimeSlice);
            // console.log("출결상태: ", this.totalPosts[i].state);
            // console.log("총 출석 수: ", this.totalAttendance);
            // let endTime = (this.totalPosts[i].endTime !== undefined || this.totalPosts[i].endTime !== null) && this.totalPosts[i].endTime.join('');
            // let endTimeSlice = (this.totalPosts[i].endTime !== undefined) && endTime.slice(-2);
            // || this.totalPosts[i].state === '입소'
            if (this.totalPosts[i].state === "출석") {
                this.setTotalAttendance();
            }
            // if(this.totalPosts[i].startTime !== undefined || startTimeSlice !== '00' ) {
            //   this.setAttendance();
            // }
            // if(this.totalPosts[i].endTime !== undefined && endTimeSlice !== '00') {
            //   this.setGoHome();
            // }
        }
    },
});

export { KidUiStore };
