import axios from "axios";
import { getCookie } from "../utils/cookie";

export const getFetchJson = async (URL) => {
    if (!URL) return;
    return await fetch(URL, {
        method: "get",
        headers: {
            Accept: "*/*",
        },
    })
        .then((text) => text.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export const getFaceTokenFetchJson = async (URL) => {
    // console.log("received URL:", URL);

    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((result) => {
            // console.log("getFaceImageList result", result);
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export const getFaceTokenFetchText = async (URL) => {
    console.log("received URL:", URL);

    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.text())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export const getTokenFetchJson = async (URL) => {
    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export async function postTokenFormDataFetch(URL, BODY) {
    return await fetch(URL, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: BODY,
    })
        .then((text) => text.text())
        .then((result) => {
            const resultObject = JSON.parse(result.toString());
            if (resultObject?.status === 400) {
                return BODY;
            }
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
}

export async function postTokenAxios(URL, BODY) {
    return await axios
        .post(URL, BODY, {
            headers: {
                Authorization: `Bearer ${getCookie("token")}`,
            },
        })
        .then(({ data }) => data)
        .then((result) => result)
        .catch((e) => {
            console.log(e);
        });
}

export const getTokenFetchText = async (URL) => {
    return await fetch(URL, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.text())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export const postTokenFetchText = async (URL, BODY) => {
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        Connection: "close",
        type: "getUserData",
        body: JSON.stringify(BODY),
    })
        .then((text) => text.text())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
};

export const postFetchJson = async (URL, BODY) => {
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
        },
        body: JSON.stringify(BODY),
    })
        .then((text) => text.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("postFetchJson : ", error);
        });
};

export const postFetchText = async (URL, BODY) => {
    return await fetch(URL, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
        },
        body: JSON.stringify(BODY),
    })
        .then((text) => text.text())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("postFetchText : ", error);
        });
};

export const putTokenFetchJsonText = async (URL, BODY) => {
    return await fetch(URL, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
        body: JSON.stringify(BODY),
    })
        .then((text) => text.text())
        .then((r) => {
            return r;
        })
        .catch((error) => {
            console.log("putTokenFetchText", error);
        });
};

export const putTokenFetchText = async (URL) => {
    return await fetch(URL, {
        method: "put",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.text())
        .then((r) => {
            return r;
        })
        .catch((error) => {
            console.log("putTokenFetchText", error);
        });
};

export async function getServerFetchFile(URL) {
    const name = decodeURI(URL.split("/").pop());
    console.log("name", name);
    return await fetch(URL, {
        method: "get",
    })
        .then((text) => text.blob())
        .then((blob) => {
            let url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.setAttribute("href", url);
            a.setAttribute("download", name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.log("serverFetch : ", error);
        });
}

export async function deleteServerFetchText(URL) {
    return await fetch(URL, {
        method: "delete",
        headers: {
            Authorization: `Bearer ${getCookie("token")}`,
        },
    })
        .then((text) => text.text())
        .then((r) => {
            return r;
        })
        .catch((error) => {
            console.log("putTokenFetchText", error);
        });
}

export async function deleteAxios(URL) {
    const authTokenStr = `Bearer ${getCookie("token")}`;
    return await axios
        .delete(URL, {
            headers: {
                Authorization: authTokenStr,
            },
        })
        .then(({ data: status }) => {
            return status;
        })
        .then((r) => r)
        .catch((error) => {
            console.log("deleteTokenAxios", error);
        });
}
