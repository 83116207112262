import {Cookies} from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name, value, option) => {
    if (value !== undefined) return cookies.set(name, value, option);
    // if (value !== undefined) return cookies.set(name, value, { ...option, domain: '.likuwithme.com' });
}

export const getCookie = (name) => {
    return cookies.get(name);
}

export const removeCookie = (name, option) => {
    return cookies.remove(name, option);
    // return cookies.remove(name, { ...option, domain: '.likuwithme.com' });
}

export const getAllCookie = () => {
    return cookies.getAll();
}

export const removeAllCookie = () => {
    let cookieAll = getAllCookie();
    for (let cookie of Object.keys(cookieAll)) {
        removeCookie(cookie, {path: '/', maxAge: 43200});
    }
}

export const refreshCookie = () => {
    let cookieAll = getAllCookie();
    for (let cookie of Object.keys(cookieAll)) {
        setCookie(cookie, cookieAll.cookie, {path: '/', maxAge: 43200});
        // setCookie(cookie, cookieAll.cookie, {path: '/', maxAge: 43200, domain: '.likuwithme.com'});
    }
}
